import React, { useState, useEffect } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Auth, API } from 'aws-amplify';
import CountUp from 'react-countup';
import * as queries from '../../graphql/queries';
import { getUserGroups } from "../../utils/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { CText } from "../../components/Text";
import ProfilePicture from "../../components/ProfilePicture/index";
import NoTeamsCard from "../../components/Cards/NoTeamsCard";
import Skeleton from '@mui/material/Skeleton';
import { updateTeamNameInPath } from "../../utils/urlRedirections";
import { getCurrentSeason } from "../../utils/season";

import {
  ArcElement,
  BarElement,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar, Chart, Doughnut, Line } from 'react-chartjs-2'

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_PINK_TRANSPARENT = 'rgba(233, 33, 99, 0.4)'
const COLOR_BLUE = "#25a5e6";
const COLOR_BLUE_TRANSPARENT = 'rgba(37, 165, 230, 0.4)';
const COLOR_PURPLE = "#9d44fd";
const COLOR_PURPLE_TRANSPARENT = 'rgba(157, 68, 253, 0.4)';
const COLOR_ORANGE = "#f2a624";
const COLOR_DARK = "#242834";
const COLOR_ORANGE_TRANSPARENT = 'rgba(242, 166, 36, 0.4)'
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;
const BORDER_RADIUS = 18;

const CardPlayer = ({ player, value, labelKey }) => {
  const useStyles = makeStyles((theme) => ({
    photo: {
      width: 80,
      height: 80,
      borderRadius: 100,
      border: '2px solid',
      marginBottom: 10
    },
    playerCard: {
      borderRadius: BORDER_RADIUS,
      backgroundColor: COLOR_DARK,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    playerCardInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flexGrow: 1
    },
    playerCardName: {
      width: '100%',
      height: 50,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center'
    },
  }))

  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={4}
    >
      <div className={classes.playerCard}>
        <ProfilePicture className={classes.photo} src={player.photo_path} />
        <div className={classes.playerCardInfo}>
          <CText className={classes.playerCardName} level="p3" color="light">{player?.first_name} {player?.last_name}</CText>
          <CText level="p3" fontWeight="700" color="light">{value ?? 0} {labelKey}</CText>
        </div>
      </div>
    </Grid>
  );
};

const TopScorers = ({teamId, season}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%'
    },
    title: {
      marginBottom: 30
    },
    statsZone: {
      width: '100%',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: BORDER_RADIUS,
      listStyleType: 'none',
      minHeight: 200,
      [theme.breakpoints.up('xs')]: {
        padding: 25
      },
      [theme.breakpoints.up('sm')]: {
        padding: 25
      },
      [theme.breakpoints.up('md')]: {
        padding: 25
      },
      [theme.breakpoints.up('lg')]: {
        padding: 35
      }
    },
    photo: {
      width: 80,
      height: 80,
      borderRadius: 100,
      border: '2px solid',
      marginBottom: 10
    },
    content: {
      display: 'flex',
      justifyContent: 'center'
    },
    contentInner: {
      maxWidth: 600,
      flex: 1
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  const [players, setPlayers] = useState([]);

  useEffect(() => {  
    const fetchData = async () => {
      getTopScorers(teamId, season)
    }
  
    fetchData()
  }, [])

  const getTopScorers = async (teamId, season) => {
    try {
      let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/teams/v1/teams/${teamId}/scorers?season=${season}&limit=3`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      });
      const respBody = await resp.json()
      setPlayers(respBody)
    } catch (error) {
      console.error("Erreur :", error);
    }
  };

  return(
    <div className={classes.statsZone}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light">{t('team_stats.1.title')}</CText>
      <div className={classes.content}>
        <div className={classes.contentInner}>
          {players.length > 0 ? (
            <Grid container spacing={1} alignItems="center">
              {players.map((player, index) => (
                <CardPlayer
                  key={index}
                  player={player}
                  value={player?.goals}
                  labelKey={t('common.goals')}
                />
              ))}
            </Grid>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const TopAssisters = ({teamId, season}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%'
    },
    title: {
      marginBottom: 30
    },
    statsZone: {
      width: '100%',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: BORDER_RADIUS,
      listStyleType: 'none',
      minHeight: 200,
      [theme.breakpoints.up('xs')]: {
        padding: 25
      },
      [theme.breakpoints.up('sm')]: {
        padding: 25
      },
      [theme.breakpoints.up('md')]: {
        padding: 25
      },
      [theme.breakpoints.up('lg')]: {
        padding: 35
      }
    },
    photo: {
      width: 80,
      height: 80,
      borderRadius: 100,
      border: '2px solid',
      marginBottom: 10
    },
    content: {
      display: 'flex',
      justifyContent: 'center'
    },
    contentInner: {
      maxWidth: 600,
      flex: 1
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  const [players, setPlayers] = useState([]);

  useEffect(() => {  
    const fetchData = async () => {
      getTopAssisters(teamId, season)
    }
  
    fetchData()
  }, [])

  const getTopAssisters = async (teamId, season) => {
    try {
      let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/teams/v1/teams/${teamId}/assisters?season=${season}&limit=3`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      });
      const respBody = await resp.json()
      setPlayers(respBody)
    } catch (error) {
      console.error("Erreur :", error);
    }
  };

  return(
    <div className={classes.statsZone}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light">{t('team_stats.2.title')}</CText>
      <div className={classes.content}>
        <div className={classes.contentInner}>
          {players.length > 0 ? (
            <Grid container spacing={1} alignItems="center">
              {players.map((player, index) => (
                <CardPlayer
                  key={index}
                  player={player}
                  value={player?.assists}
                  labelKey={t('common.assists')}
                />
              ))}
            </Grid>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const GoalsEvol = ({ matches, teamId }) => {
  const useStyles = makeStyles((theme) => ({
    container: {
    },
    title: {
      marginBottom: 30
    },
    statsZone: {
      display: 'block',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: BORDER_RADIUS,
      listStyleType: 'none',
      minHeight: 200,
      [theme.breakpoints.up('xs')]: {
        padding: 25
      },
      [theme.breakpoints.up('sm')]: {
        padding: 25
      },
      [theme.breakpoints.up('md')]: {
        padding: 25
      },
      [theme.breakpoints.up('lg')]: {
        padding: 35
      }
    },
    stat: {
      maxHeight: 300
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  const [goalsByWeek, setGoalsByWeek] = useState([]);
  const [goalsConcededByWeek, setGoalsConcededByWeek] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const goals = sortGoalsByWeek(matches, teamId);
    const goalsConceded = sortGoalsByWeek(matches, teamId, true);
    setGoalsByWeek(goals);
    setGoalsConcededByWeek(goalsConceded);
    let maxWeek = getMaxWeek(matches)
    maxWeek > 10 ? maxWeek = maxWeek : maxWeek = 10
    const labels = [];
    for (let i = 1; i <= maxWeek; i++) {
      labels.push(`${t('team_stats.0.labels.2')} ${i}`);
    }
    setLabels(labels);
  }, [matches, teamId]);

  const sortGoalsByWeek = (matches, teamId, isGoalsConceded = false) => {
    const goalsByWeek = [];
  
    // Filtrage des matchs par ID de l'équipe
    const filteredMatches = matches.filter((match) => match.home_team_id === teamId || match.away_team_id === teamId);
  
    // Tri des matchs filtrés par ordre croissant des semaines
    const sortedMatches = filteredMatches.sort((a, b) => a.week - b.week);
  
    // Parcours des matchs et ajout des buts marqués ou concédés dans le tableau par semaine
    sortedMatches.forEach((match) => {
      const { week, home_team_goals, away_team_goals } = match;
  
      // Vérification s'il y a des semaines manquantes entre les matchs
      if (goalsByWeek.length < week - 1) {
        const missingWeeks = week - 1 - goalsByWeek.length;
        for (let i = 0; i < missingWeeks; i++) {
          goalsByWeek.push(null);
        }
      }
  
      // Ajout des buts marqués ou concédés pour la semaine actuelle
      const goals = isGoalsConceded ? away_team_goals : home_team_goals;
      goalsByWeek.push(goals);
    });
  
    return goalsByWeek;
  };

  const getMaxWeek = (matches) => {
    let maxWeek = 0;
  
    matches.forEach((match) => {
      if (match.week > maxWeek) {
        maxWeek = match.week;
      }
    });
  
    return maxWeek;
  };
  
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: ` ${t('team_stats.0.labels.0')}`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_PRIMARY,
        borderColor: COLOR_PRIMARY,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 10.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLOR_PRIMARY,
        pointBackgroundColor: COLOR_PRIMARY,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_PRIMARY,
        pointHoverBorderColor: COLOR_PRIMARY_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointWidth: 100,
        pointRadius: 4,
        pointHitRadius: 10,
        data: goalsByWeek
      },
      {
        label: ` ${t('team_stats.0.labels.1')}`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_PINK,
        borderColor: COLOR_PINK,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 10.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLOR_PINK,
        pointBackgroundColor: COLOR_PINK,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_PINK,
        pointHoverBorderColor: COLOR_PINK_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointWidth: 100,
        pointRadius: 4,
        pointHitRadius: 10,
        data: goalsConcededByWeek
      }
    ]
  };

  let graphOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    scales: {
      y: {
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  return(
    <div className={classes.statsZone}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light" label={`${t('team_stats.0.title')}`} />
      <Line data={data} options={graphOptions} className={classes.stat} />
    </div>
  )
}

const NbVictoriesDrawsLosses = ({ matches, teamId }) => {
  const useStyles = makeStyles((theme) => ({
    title: {
      marginBottom: 30
    },
    statsZone: {
      display: 'block',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: BORDER_RADIUS,
      listStyleType: 'none',
      [theme.breakpoints.up('xs')]: {
        padding: 25
      },
      [theme.breakpoints.up('sm')]: {
        padding: 25
      },
      [theme.breakpoints.up('md')]: {
        padding: 25
      },
      [theme.breakpoints.up('lg')]: {
        padding: 35
      }
    },
    stat: {
      maxHeight: 300
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  //const [players, setPlayers] = useState([]);

  const [winCount, setWinCount] = useState(0);
  const [drawCount, setDrawCount] = useState(0);
  const [lossCount, setLossCount] = useState(0);

  useEffect(() => {
    const { winCount, drawCount, lossCount } = countMatchResults(matches, teamId);
    setWinCount(winCount);
    setDrawCount(drawCount);
    setLossCount(lossCount);
  }, [matches, teamId]);

  const countMatchResults = (matches, teamId) => {
    const sortedMatches = matches.sort((a, b) => new Date(b.date) - new Date(a.date));
  
    let winCount = 0;
    let drawCount = 0;
    let lossCount = 0;
  
    sortedMatches.forEach((match) => {
      if (match.home_team_id === teamId && match.home_team_goals > match.away_team_goals) {
        winCount++;
      } else if (match.away_team_id === teamId && match.away_team_goals > match.home_team_goals) {
        winCount++;
      } else if (match.home_team_goals === match.away_team_goals) {
        drawCount++;
      } else {
        lossCount++;
      }
    });
  
    return { winCount, drawCount, lossCount };
  };

  const data = {
    labels: [ t('team_stats.3.labels.0'), t('team_stats.3.labels.1'), t('team_stats.3.labels.2') ],
    datasets: [
      {
        label: '',
        data: [winCount, drawCount, lossCount],
        backgroundColor: [
          COLOR_PRIMARY,
          COLOR_ORANGE,
          COLOR_PINK,
        ],
        borderColor: [
          COLOR_PRIMARY,
          COLOR_ORANGE,
          COLOR_PINK
        ],
        borderWidth: 1,
        offset: 20
      },
    ]
  };

  let options = {
    responsive: true
  };

  return(
    <div className={classes.statsZone}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light" label={`${t('team_stats.3.title')}`} />
      <Doughnut data={data} options={options} className={classes.stat} />
    </div>
  )
}

const GoalsPerGame = ({ matches, teamId }) => {
  const useStyles = makeStyles((theme) => ({
    title: {
      marginBottom: 30
    },
    statsZone: {
      display: 'block',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: BORDER_RADIUS,
      listStyleType: 'none',
      [theme.breakpoints.up('xs')]: {
        padding: 25
      },
      [theme.breakpoints.up('sm')]: {
        padding: 25
      },
      [theme.breakpoints.up('md')]: {
        padding: 25
      },
      [theme.breakpoints.up('lg')]: {
        padding: 35
      }
    },
    stat: {
      maxHeight: 300
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  const [averageGoalsScored, setAverageGoalsScored] = useState(0);
  const [averageGoalsConceded, setAverageGoalsConceded] = useState(0);

  useEffect(() => {
    const { averageGoalsScored, averageGoalsConceded } = calculateGoalsStats(matches, teamId);
    setAverageGoalsScored(averageGoalsScored);
    setAverageGoalsConceded(averageGoalsConceded);
  }, [matches, teamId]);

  const calculateGoalsStats = (matches, teamId) => {
    let goalsScored = 0;
    let goalsConceded = 0;
  
    matches.forEach((match) => {
      if (match.home_team_id === teamId) {
        goalsScored += match.home_team_goals;
        goalsConceded += match.away_team_goals;
      } else if (match.away_team_id === teamId) {
        goalsScored += match.away_team_goals;
        goalsConceded += match.home_team_goals;
      }
    });
  
    const averageGoalsScored = goalsScored / matches.length;
    const averageGoalsConceded = goalsConceded / matches.length;
  
    return { averageGoalsScored, averageGoalsConceded };
  }; 

  const data = {
    labels: [ "" ],
    datasets: [
      {
        label: `${t('team_stats.4.labels.0')}`,
        data: [averageGoalsScored],
        backgroundColor: COLOR_PRIMARY,
        borderColor: COLOR_PRIMARY
      },
      {
        label: `${t('team_stats.4.labels.1')}`,
        data: [averageGoalsConceded],
        backgroundColor: COLOR_PINK,
        borderColor: COLOR_PINK
      }
    ],
  };

  let options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1
  };

  return(
    <div className={classes.statsZone}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light" label={`${t('team_stats.4.title')}`} />
      <Bar data={data} options={options} className={classes.stat} />
    </div>
  )
}

const LastGames = ({ teamId, matches, numberMatches }) => {
  const useStyles = makeStyles((theme) => ({
    title: {
      marginBottom: 30
    },
    statsZone: {
      display: 'block',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: BORDER_RADIUS,
      listStyleType: 'none',
      [theme.breakpoints.up('xs')]: {
        padding: 25
      },
      [theme.breakpoints.up('sm')]: {
        padding: 25
      },
      [theme.breakpoints.up('md')]: {
        padding: 25
      },
      [theme.breakpoints.up('lg')]: {
        padding: 35
      }
    },
    stat: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 80
    },
    result: {
      marginLeft: 5,
      marginRight: 5,
      width: 20,
      height: 20,
      borderRadius: '50%'
    },
    resultWin: {
      backgroundColor: COLOR_PRIMARY
    },
    resultDraw: {
      backgroundColor: COLOR_ORANGE
    },
    resultLoss: {
      backgroundColor: COLOR_PINK
    },
    resultEmpty: {
      backgroundColor: COLOR_DARK
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  const [lastGamesResults, setLastGamesResults] = useState([]);

  useEffect(() => {
    getLastGameResults();
  }, []);

  const getLastGameResults = async () => {
    try {
      const numMatches = numberMatches;
  
      if (!Array.isArray(matches) || matches.length === 0) {
        const emptyResults = Array(numMatches).fill(null);
        setLastGamesResults(emptyResults);
        return;
      }
  
      const sortedMatches = matches.sort((a, b) => new Date(b.date) - new Date(a.date));
  
      const recentMatches = sortedMatches.slice(0, numMatches);
  
      let matchResults = recentMatches.map((match) => {
        let result = "D";
  
        if (
          match.home_team_id && match.away_team_id &&
          typeof match.home_team_goals === 'number' &&
          typeof match.away_team_goals === 'number'
        ) {
          if (match.home_team_id === teamId && match.home_team_goals > match.away_team_goals) {
            result = "W";
          } else if (match.away_team_id === teamId && match.away_team_goals > match.home_team_goals) {
            result = "W";
          } else if (match.home_team_goals === match.away_team_goals) {
            result = "D";
          } else {
            result = "L";
          }
        } else {
          result = "N/A";
        }
  
        return result;
      });
  
      const emptyResultsCount = numMatches - matchResults.length;
      const emptyResults = Array(emptyResultsCount).fill(null);
      matchResults = [...matchResults, ...emptyResults];
  
      setLastGamesResults(matchResults);
    } catch (err) {
      console.log("Error in getLastGameResults: ", err);
    }
  };
  

  let Result = (gameResult, index) => {
    let res;

    switch (gameResult) {
      case 'W':
        res = <div key={index} className={[classes.result, classes.resultWin].join(" ")} />
        break;
      case 'D':
        res = <div key={index} className={[classes.result, classes.resultDraw].join(" ")} />
        break;
      case 'L':
        res = <div key={index} className={[classes.result, classes.resultLoss].join(" ")} />
        break;
      default:
        res = <div key={index} className={[classes.result, classes.resultEmpty].join(" ")} />
        break;
    }
    return res;
  }

  return(
    <div className={classes.statsZone}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light" label={`${t('team_stats.5.title')}`} />
      <div className={classes.stat}>
        {lastGamesResults.map((gameResult, index) => (
          Result(gameResult, index)
        ))}
      </div>
    </div>
  )
}

const BestSeries = ({ teamId, season }) => {
  const useStyles = makeStyles((theme) => ({
    title: {
      marginBottom: 30
    },
    statsZone: {
      display: 'block',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: BORDER_RADIUS,
      listStyleType: 'none',
      [theme.breakpoints.up('xs')]: {
        padding: 25
      },
      [theme.breakpoints.up('sm')]: {
        padding: 25
      },
      [theme.breakpoints.up('md')]: {
        padding: 25
      },
      [theme.breakpoints.up('lg')]: {
        padding: 35
      }
    },
    stat: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 80
    },
    serie: {
      color: COLOR_PRIMARY,
      width: 70,
      height: 70,
      borderRadius: '50%',
      border: `3px solid ${COLOR_PRIMARY}`,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();


  const [counterOn, setCounterOn] = useState(false);
  const [longestWinningStreak, setLongestWinningStreak] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      getLongestWinningStreak(teamId, season)
    }
  
    fetchData();

    const pageHeight = window.innerHeight
    const y = myRef.current.getBoundingClientRect().top;
    if(pageHeight >= y) {
      setCounterOn(true)
    } else {
      window.addEventListener('scroll', onScroll, { passive: true });
    }

  }, []);

  const getLongestWinningStreak = async (teamId, season) => {
    try {
      let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/teams/v1/teams/${teamId}/longest-winning-streak?season=${season}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      });
      const respBody = await resp.json()

      setLongestWinningStreak(respBody.count)

    } catch (error) {
      console.error("Erreur :", error);
    }
  };


  const myRef = React.useRef();

  const onScroll = () => {
    const pageHeight = window.innerHeight
    const y = myRef.current.getBoundingClientRect().top;
    if(pageHeight >= y) {
      setCounterOn(true)
    }
  };

  return(
    <div className={classes.statsZone} ref={myRef}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light" label={`${t('team_stats.6.title')}`} />
      <div className={classes.stat}>
        <CText className={classes.serie} level="h1" label={counterOn ? <CountUp end={longestWinningStreak} duration={0.6} delay={0.5} /> : null }/>
      </div>
    </div>
  )
}

const PerformanceEvol = ({ matches, teamId }) => {
  const useStyles = makeStyles((theme) => ({
    title: {
      marginBottom: 30
    },
    statsZone: {
      display: 'block',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: BORDER_RADIUS,
      listStyleType: 'none',
      [theme.breakpoints.up('xs')]: {
        padding: 25
      },
      [theme.breakpoints.up('sm')]: {
        padding: 25
      },
      [theme.breakpoints.up('md')]: {
        padding: 25
      },
      [theme.breakpoints.up('lg')]: {
        padding: 35
      }
    },
    stat: {
      maxHeight: 300
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  const [monthlyStats, setMonthlyStats] = useState({ matchCountByMonth: [], pointCountByMonth: [], ratioByMonth: [] });

  useEffect(() => {
    const calculatedStats = calculateMonthlyStats(matches, teamId);
    setMonthlyStats(calculatedStats);
  }, [matches, teamId]);

  const calculateMonthlyStats = (matches, teamId) => {
    const matchCountByMonth = Array(12).fill(0);
    const pointCountByMonth = Array(12).fill(0);
    const ratioByMonth = Array(12).fill(null);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    matches.forEach((match) => {
      const matchDate = new Date(match.date);
      const matchMonth = matchDate.getMonth();
      const matchYear = matchDate.getFullYear();

      const currentSlidingMonth = (currentMonth + 5) % 12;
      const matchSlidingMonth = (matchMonth + 5) % 12;

      if (matchYear > currentYear || (matchYear === currentYear && matchSlidingMonth > currentSlidingMonth)) {
        return;
      }

      let matchPoints = 0;
      if (match.home_team_id === teamId && match.home_team_goals > match.away_team_goals) {
        matchPoints = 3;
      } else if (match.away_team_id === teamId && match.away_team_goals > match.home_team_goals) {
        matchPoints = 3;
      } else if (match.home_team_goals === match.away_team_goals) {
        matchPoints = 1;
      }

      matchCountByMonth[matchSlidingMonth] += 1;
      pointCountByMonth[matchSlidingMonth] += matchPoints;
    });

    // Calculer le ratio de points par match joué pour chaque mois
    for (let i = 0; i < 12; i++) {
      if (matchCountByMonth[i] > 0) {
        ratioByMonth[i] = pointCountByMonth[i] / matchCountByMonth[i];
      } else {
        ratioByMonth[i] = (i <= (currentMonth + 5) % 12) ? 0 : null; // Affiche 0 ou null selon que le mois soit passé ou futur
      }
    }

    return {
      matchCountByMonth,
      pointCountByMonth,
      ratioByMonth
    };
  };

  const data = {
    labels: [
      t('common.months.7'), t('common.months.8'), t('common.months.9'), t('common.months.10'),
      t('common.months.11'), t('common.months.0'), t('common.months.1'), t('common.months.2'),
      t('common.months.3'), t('common.months.4'), t('common.months.5'), t('common.months.6')
    ],
    datasets: [
      {
        type: 'line',
        label: `${t('team_stats.7.labels.0')}`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_PURPLE,
        borderColor: COLOR_PURPLE,
        pointBorderColor: COLOR_PURPLE,
        pointBackgroundColor: COLOR_PURPLE,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_PURPLE,
        pointHoverBorderColor: COLOR_PURPLE_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointRadius: 4,
        pointHitRadius: 10,
        data: monthlyStats.ratioByMonth
      },
      {
        type: 'bar',
        label: `${t('team_stats.7.labels.1')}`,
        data: monthlyStats.pointCountByMonth,
        backgroundColor: COLOR_PRIMARY,
        borderColor: COLOR_PRIMARY
      },
      {
        type: 'bar',
        label: `${t('team_stats.7.labels.2')}`,
        data: monthlyStats.matchCountByMonth,
        backgroundColor: COLOR_BLUE,
        borderColor: COLOR_BLUE
      }
    ],
  };

  let options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    scales: {
      y: {
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  return (
    <div className={classes.statsZone}>
      <CText className={classes.title} level="h3" fontWeight="700" color="light" label={`${t('team_stats.7.title')}`} />
      <Chart data={data} options={options} className={classes.stat} />
    </div>
  );
};

const Stats = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        marginLeft: MENU_WIDTH
      }
    },
    title: {
    },
    noTeamsInfo: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    topContainer: {
      display: 'inline-block',
      width: '100%',
      marginTop: 15,
      marginBottom: 30
    },
    topContainerLeft: {
      display: 'flex',
      flexDirection: 'row',
      float: 'left'
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const userStored = useSelector(state => state?.user);
  const teamId = userStored?.club?.team?.id;

  const [isLoaded, setIsLoaded] = useState(false);
  const [season, setSeason] = useState();
  const [matches, setMatches] = useState();
  const [userGroups, setUserGroups] = useState([]);
  const [teams, setTeams] = useState([]);

  const getTeams = async() => {
    try {
      const { groups, club } = userStored;
      let teams = [];
    
      if (groups.includes("ClubAdmins")) {
        const clubId = club?.id

        const teamsFilter = {
          club_id: { eq: clubId },
          deleted: { eq: false }
        }
  
        let teamsList = await API.graphql({ query: queries.listClubTeams, variables: { filter: teamsFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS'})
        teamsList = teamsList.data.listTeams.items

        const teamsListOrdered = teamsList.sort((a, b) => {
          const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
          const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
          return numberA - numberB;
        });
  
        teams = teamsListOrdered;

      } else {
        const team = club?.team;
        if(team?.id) { teams.push(team) };

      }

      setTeams(teams)

      return teams;
    }
    catch(err) { console.log(err) }
  }

  const getMatches = async (teamId, season) => {
    try {
      let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/teams/v1/teams/${teamId}/results?season=${season}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      });
      const respBody = await resp.json()

      return respBody
    } catch (error) {
      console.error("Erreur :", error);
    }
  };

  useEffect(() => {
    updateTeamNameInPath(location.pathname, userStored?.club?.team?.id, navigate);
  }, [location.pathname, userStored?.club?.team?.name, navigate]);

  useEffect(() => {  
    const fetchData = async () => {
      if (!isLoaded) {
        const userGroups = await getUserGroups();
        setUserGroups(userGroups);
        const currentSeason = await getCurrentSeason();
        setSeason(currentSeason);
        const teams = await getTeams();

        if (teams.length > 0) {
          const teamMatches = await getMatches(teamId, currentSeason);
          setMatches(teamMatches);
          setTeams(teams);
        }

        setIsLoaded(true);
      }
    }

    fetchData();
  }, [isLoaded, teamId]);

  // Handle CONTENT
  let CONTENT;
  if(isLoaded) {
    if ((userGroups.includes("ClubAdmins") || userGroups.includes("Coaches")) && teams.length < 1) {
      CONTENT =
      <div className={classes.noTeamsInfo}>
        <NoTeamsCard redirectBtn={userGroups.includes("ClubAdmins") ? true : false} />
      </div>
    } else {
      CONTENT =
        <div>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid container item xs={12} sm={12} md={6} alignItems="center">
              <TopScorers season={season} teamId={teamId} />
            </Grid>
            <Grid container item xs={12} sm={12} md={6} alignItems="center">
              <TopAssisters season={season} teamId={teamId} />
            </Grid>
            <Grid container item xs={12} md={6} alignItems="center">
              <LastGames teamId={teamId} matches={matches} numberMatches={7} />
            </Grid>
            <Grid container item xs={12} md={6} alignItems="center">
              <BestSeries season={season} teamId={teamId} />
            </Grid>
            <Grid container item xs={12} md={6} alignItems="center">
              <NbVictoriesDrawsLosses matches={matches} teamId={teamId} />
            </Grid>
            <Grid container item xs={12} md={6} alignItems="center">
              <GoalsPerGame matches={matches} teamId={teamId} />
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <GoalsEvol matches={matches} teamId={teamId} />
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <PerformanceEvol matches={matches} teamId={teamId}  />
            </Grid>
          </Grid>
        </div>
    }
  } else {
    CONTENT = <Grid container spacing={1} alignItems="flex-start">
      <Grid container item xs={12} sm={12} md={6} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container item xs={12} sm={12} md={6} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container item xs={12} md={6} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container item xs={12} md={6} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container item xs={12} md={6} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container item xs={12} md={6} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <Skeleton variant="rounded" width="100%" height={118} sx={{ borderRadius: BORDER_RADIUS - 13 }} />
      </Grid>
    </Grid>
  }

  return(
    <div className={classes.container}>
      <CText className={classes.title} level="h1" color="light">{t('common.team_stats')}</CText>
      <div className={classes.topContainer}>
      </div>
      {CONTENT}
    </div>
  )
}
  
export { Stats };