import React, { useState } from 'react';
import TextInput from '../../components/TextInput.js';
import CButton from "../../components/Button.js";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import { CText } from "../../components/Text";
import { useDispatch } from 'react-redux';
import { sessionHandler } from '../../utils/auth';
import { Helmet } from 'react-helmet';
import authentImg from '../../assets/images/authent.svg';
import { useStylesAuth } from './auth.styles.js';
import { signIn } from '../../store/reducers/user';
import { BadRequestSnackBar } from "../../components/SnackBar";

const COLOR_PRIMARY = '#45d69f';

const SignIn = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const classes = useStylesAuth();

  const [openSnack, setOpenSnack] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("")
  const [snackBarErrorElmts, setSnackBarErrorElmts] = useState([])

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(false);

  // Click Buttons
  const signInClick = async() => {
    let errorMessage;
    if(email && password) {
      // Call backend top pass data form
      try {
        const user = await Auth.signIn(email, password);
        if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log("Change password.");
          navigate({
            pathname: `/auth/new_password`,
            search: createSearchParams({
              email: email,
              password: password
            }).toString()
          });
          window.scrollTo(0, 0);
        } else {
          console.log("User authenticated.");
          await dispatch(signIn());
          await sessionHandler();
        }
      } catch (error) {
        console.log('error signing in', error);
        setOpenSnack(true);
        setSnackBarErrorMessage(t('sign_in.errors.1.message'))
      }
    } else {
      setSnackBarErrorMessage("")
      setSnackBarErrorElmts([])
      let errorElmts = []
      if(!email) {
        errorElmts.push(t('sign_in.inputs.0.label'))
      }
      if(!password) {
        errorElmts.push(t('sign_in.inputs.1.label'))
      }

      setOpenSnack(true)
      setSnackBarErrorMessage(t('sign_in.errors.0.message'))
      setSnackBarErrorElmts(errorElmts)
    }

    setSnackBarMessage(errorMessage);
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setSnackBarErrorMessage("");
  };

  const resetPwd = () => {
    navigate(`/recover/initiate`);
    window.scrollTo(0, 0);
  }

  const signUp = () => {
    navigate(`/auth/sign_up`);
    window.scrollTo(0, 0);
  }

  // Infos Callbacks
  const emailCb = (data) =>{ setEmail(data) };
  const passwordCb = (data) =>{ setPassword(data) };

  return(
    <div className={classes.container}>
      <Helmet>
        <title>{t('sign_in.head.title')}</title>
        <meta name="description" content={t('sign_in.head.meta')} />
      </Helmet>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={false} md={6} className={classes.form}>
          <div className={classes.signFormZone}>
            <TextInput className={classes.input} label={t('sign_in.inputs.0.label')} inputCallback={emailCb} />
            <TextInput className={classes.lastInput} label={t('sign_in.inputs.1.label')} type="password" inputCallback={passwordCb} />
            <div className={classes.labelBtnWrapper}>
              <button className={classes.labelBtn} onClick={() => resetPwd()}>
                <CText
                  level="p3"
                  fontWeight="700"
                  color="primary"
                  className={classes.labelBtnLabel}
                  label={t('sign_in.buttons.1.label')}
                />
              </button>
            </div>
            <CButton
              className={classes.formBtn}
              label={t('sign_in.buttons.0.label')}
              height={38}
              type="contained"
              loader={true}
              onClick={async () => await signInClick()}
            />
          </div>
          <div className={classes.signFormZone2}>
            <CText level="p2" color="light" className={classes.formZone2Label}>
              {t('sign_in.messages.0.label')}
              <button className={classes.labelBtn} onClick={() => signUp()}>
                <CText
                  level="p2"
                  fontWeight="700"
                  color="primary"
                  className={classes.labelBtnLabel}
                >
                  {t('sign_in.buttons.2.label')}
                </CText>
              </button>
            </CText>
          </div>
        </Grid>
        <Grid item xs={false} sm={false} md={6}>
          <img src={authentImg} className={classes.image} />
        </Grid>
      </Grid>
      <BadRequestSnackBar
        open={openSnack}
        message={snackBarErrorMessage}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        errorElmts={snackBarErrorElmts}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </div>
  );
}

export default SignIn;