import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { CText } from "../../components/Text";
import { CRadio } from '../../components/Radio/Radio';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';
import languagesReferential from '../../assets/referentials/languages.json';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const CARD_WIDTH = 450;

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      maxWidth: CARD_WIDTH
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: CARD_WIDTH + 15,
    },
  },
  titleIcon: {
    stroke: COLOR_LIGHT,
    fill: COLOR_LIGHT,
    width: 22,
    height: 22,
    backgroundColor: ZONE_BACKGROUND_COLOR,
    padding: 7,
    borderRadius: '50%',
    cursor: 'pointer',
    "&:hover": {
      opacity: '0.8',
    },
    marginRight: 15,
  },
  languageInfoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  list: {
    width: '100%',
    padding: 0,
    margin: 0,
  }
}));

const LanguageInfo = ({ language, selectedValue, handleChangeCB }) => {
  const useStyles = makeStyles((theme) => ({
    dataZone: {
      display: 'flex',
      boxSizing: 'border-box',
      width: '100%',
      flexDirection: 'row',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      border: `1px solid ${ZONE_BACKGROUND_COLOR}`,
      boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.3)',
      borderRadius: 15,
      listStyleType: 'none',
      cursor: 'pointer',
      [theme.breakpoints.up('xs')]: {
        padding: 15,
        maxWidth: CARD_WIDTH
      },
      [theme.breakpoints.up('md')]: {
        padding: 15,
        maxWidth: CARD_WIDTH,
      },
      "&&:hover": {
        filter: 'brightness(95%)',
      },
    },
    dataZoneSelected: {
      border: `1px solid ${COLOR_PRIMARY}`,
    },
    label: {
      textAlign: 'center',
      marginRight: 'auto',
    },
    flag: {
      marginRight: 8,
      fontSize: 26,
    },
  }));

  const classes = useStyles();

  const handleChange = (value) => {
    handleChangeCB(value);
  };

  return (
    <li
      className={[
        classes.dataZone,
        selectedValue === language.code ? classes.dataZoneSelected : ''
      ].join(' ')}
      onClick={() => handleChange(language.code)}
    >
      <CText className={classes.label} level="p2" fontWeight={700} color="light">
        <span className={classes.flag}>{language.flag}</span>{language.label}
      </CText>
      <CRadio
        value={language.code}
        checked={selectedValue === language.code}
      />
    </li>
  );
};

const Languages = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const userStored = useSelector((state) => state?.user);

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const saveSelectedLanguage = (value) => {
    setSelectedLanguage(value);
    i18n.changeLanguage(value);
    localStorage.setItem("selectedLanguage", value);
  };

  const handlePrevious = () => {
    const path = location.state?.prevUrl || location.pathname;
    const queryParams = new URLSearchParams(location.search);
    const encodedTeamName = encodeURIComponent(userStored?.club?.team?.name);

    const regex = /(.*)\/teams\/([^/]+)(\/.*)?/;
    const match = path.match(regex);

    if (match) {
      const beforeTeams = match[1] || '';
      const afterTeamName = match[3] || '';
      const newPath = `${beforeTeams}/teams/${encodedTeamName}${afterTeamName}`;
      navigate(newPath + location.search);
    } else if (queryParams.has('team')) {
      queryParams.set('team', encodedTeamName);
      const newPathWithQuery = `${path.split('?')[0]}?${queryParams.toString()}`;
      navigate(newPathWithQuery);
    } else {
      navigate(-1);
    }

    window.scrollTo(0, 0);
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <LeftArrowIcon className={classes.titleIcon} onClick={handlePrevious} />
        <CText level="h1" color="light" label={`${t('language.title')}`} />
      </div>

      <ul className={classes.list}>
        <Grid container spacing={1}>
          {languagesReferential.map((language, index) => (
            <Grid item xs={12} key={index} className={classes.languageInfoContainer}>
              <LanguageInfo
                language={language}
                selectedValue={selectedLanguage}
                handleChangeCB={saveSelectedLanguage}
              />
            </Grid>
          ))}
        </Grid>
      </ul>
    </div>
  );
};

export default Languages;