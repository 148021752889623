import React, { useState } from 'react';
import CButton from "../../components/Button.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from 'aws-amplify';
import { CText } from "../../components/Text";
import { sessionHandler } from '../../utils/auth';
import { useDispatch } from 'react-redux';
import InputCode from '../../components/Input/InputCode';
import { useStylesAuth } from './auth.styles.js';
import { signIn } from '../../store/reducers/user';
import { SnackBarError } from "../../components/SnackBar";

const COLOR_PRIMARY = '#45d69f';
const TOP_BAR_HEIGHT = 56;

const VerificationCode = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  
  const classes = useStylesAuth()
  
  const CODE_LENGTH = 6;
  const [code, setCode] = useState(null);

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const password = searchParams.get("password");
  const invitationId = searchParams.get("invitation");

  // Click Buttons
  const submitClick = async() => {
    try {
      if(code) {
        await Auth.confirmSignUp(email, code)
        //console.log("Code verified.");
        //console.log("User authenticated.")

        const user = await Auth.signIn(email, password);
        const userInfo = user.signInUserSession.accessToken;
        const groups = userInfo.payload['cognito:groups'];

        // Handle invitation for new users
        if(groups?.includes("Coaches") || groups?.includes("Players")) {
          if(invitationId && invitationId !== null && invitationId !== "null") {
            const input = { invite_status: 1 };

            await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/invitations/v1/invitations/${invitationId}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
              },
              body: JSON.stringify(input)
            });
          }
        }

        await dispatch(signIn());

        setTimeout(async () => {
          await sessionHandler();
        }, 100);

      } else {
        //throw 'Code length is not valid.'
      }
    } catch (error) {
      //console.log('Error signing up: ', error);
    }
  }

  const resendConfirmationCode = async() => {
    try {
      await Auth.resendSignUp(email);
      //console.log('Code resent successfully');
    } catch (err) {
      //console.log('error resending code: ', err);
    }
  }

  // Infos Callbacks
  const codeCb = (data) =>{ setCode(data) };

  return(
    <div className={classes.container}>
      <div className={classes.formZone}>
        <InputCode
          length={CODE_LENGTH}
          onComplete={codeCb}
          className={classes.inputCodeZone}
        />
        <div className={classes.labelBtnWrapper}>
          <button className={classes.labelBtn} onClick={() => resendConfirmationCode()}>
            <CText
              level="p3"
              fontWeight="700"
              color="primary"
              className={classes.labelBtnLabel}
            >
              {t('verification_code.buttons.1.label')}
            </CText>
          </button>
        </div>
        <CButton
          className={classes.formBtn}
          label={t('verification_code.buttons.0.label')}
          height={38}
          type="contained"
          loader={true}
          onClick={async () => await submitClick()}
        />
      </div>
    </div>
  );
}

export default VerificationCode;