import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './custom-calendar.css';
import CustomToolbar from './CustomToolbar';
import { useTranslation } from 'react-i18next';
import EventMenu from '../../components/Menu/EventMenu';
import { makeStyles } from "@material-ui/core/styles";
import classnames from 'classnames';

import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/pt';

// Configure Moment pour utiliser le français
const COLOR_PRIMARY = '#45d69f';
const COLOR_PINK = "#e92163";
const COLOR_BLUE = "#25a5e6";

export const useStyles = makeStyles((theme) => ({
  slot: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    border: '1px solid #313131',
    backgroundColor: (props) => {
      if (!props.isInMonth) return '#333333';
      return props.isToday ? '#646979' : 'transparent';
    },
    "&:hover": {
      backgroundColor: '#313131'
    }
  },
  date: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    fontSize: 13,
    color: '#ffffff',
    padding: '4px',
    position: 'absolute',
    top: '3px',
    right: '5px',
    textAlign: 'right',
    zIndex: 100,
    cursor: 'pointer',
    "&:hover": {
      filter: 'brightness(90%)'
    }
  },
  dateToday: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR_PRIMARY
  },
  events: {
    position: 'absolute',
    top: 27,
    left: '0',
    width: '100%'
  },
  event: {
    fontSize: 15,
    backgroundColor: COLOR_PINK,
    borderRadius: 5,
    padding: 5,
    zIndex: 100,
    position: 'relative',
    marginBottom: 5,
    "&:hover": {
      filter: 'brightness(90%)'
    }
  },
  eventMore: {
    fontSize: 15,
    fontWeight: '700',
    color: COLOR_BLUE,
    marginLeft: 'auto',
    "&:hover": {
      filter: 'brightness(90%)'
    }
  },
}));

const CustomSlot = ({ children, events, value, onSelectSlot, updateEvent, removeEvent, changeToDayView, selectable }) => {
  const isToday = moment().isSame(value, 'day');
  const isInMonth = moment(value).isSame(moment().startOf('month'), 'month');
  const classes = useStyles({ isToday, isInMonth });

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ignoreSelectSlot, setIgnoreSelectSlot] = useState(false);

  // Constante pour limiter le nombre d'événements affichés
  const MAX_EVENTS_DISPLAYED = 2;
  const displayedEvents = events.slice(0, MAX_EVENTS_DISPLAYED);
  const extraEvents = events.length - MAX_EVENTS_DISPLAYED;

  const handleClickMore = (event, selectedEvent) => {
    setSelectedEvent(selectedEvent);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedEvent(null);
  };

  const handleClickUpdateEvent = (e, data, index) => {
    if (selectedEvent) {
      updateEvent(selectedEvent.id);
    }
    handleClose();
  };

  const handleRemoveEvent = async () => {
    if (selectedEvent) {
      removeEvent(selectedEvent.id);
    }
    handleClose();
  };

  const handleDateClick = (e) => {
    e.stopPropagation();
    setIgnoreSelectSlot(true);
    changeToDayView(value);
  };

  React.useEffect(() => {
    if (ignoreSelectSlot) {
      const timeoutId = setTimeout(() => setIgnoreSelectSlot(false), 0);
      return () => clearTimeout(timeoutId);
    }
  }, [ignoreSelectSlot]);

  return (
    <div
      className={[classes.slot].join(' ')}
      onClick={(e) => {
        if(selectable) {
          e.stopPropagation();
          if (!anchorEl && !ignoreSelectSlot) {
            onSelectSlot({ start: value, end: moment(value).endOf('day').toDate() });
          }
        }
      }}
    >
      <div
        className={classnames(classes.date, {
          [classes.dateToday]: isToday,
        })}
        onClick={handleDateClick}
      >
        {moment(value).format('D')}
      </div>

      {children}

      <div className={classes.events}>
        {/* Afficher uniquement les événements limités */}
        {displayedEvents.map((event, index) => (
          <div
            role="button"
            title={event.title}
            key={index}
            className={classes.event}
            onClick={(e) => {
              if(selectable) {
                e.stopPropagation();
                handleClickMore(e, event);
              }
            }}
          >
            {event.title}
          </div>
        ))}
        {extraEvents > 0 && (
        <span
          role="button"
          className={classes.eventMore}
          onClick={(e) => {
            if(selectable) {
              e.stopPropagation();
              changeToDayView(value);
            }
          }}
        >
          {`+${extraEvents} more`}
        </span>
        )}
      </div>

      {selectedEvent && (
        <EventMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleClickUpdateEvent={handleClickUpdateEvent}
          handleRemoveEvent={handleRemoveEvent}
          data={selectedEvent}
          index={events.indexOf(selectedEvent)}
        />
      )}
    </div>
  );
};

const Scheduler = ({
  events,
  addEvent,
  updateEvent,
  removeEvent,
  currentView,
  onViewChange,
  selectable
}) => {
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);
  const localizer = momentLocalizer(moment);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ignoreNextSlotSelect, setIgnoreNextSlotSelect] = useState(false);

  const handleClickMore = (event, selectedEvent) => {
    setSelectedEvent(selectedEvent);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedEvent(null);
  };

  const customSlotUpdateEvent = (id) => {
    const index = events.findIndex((event) => event.id === id);
    if (index !== -1) {
      updateEvent(index);
    }
  };

  const handleClickUpdateEvent = (e, data, index) => {
    if (selectedEvent) {
      const index = events.findIndex((event) => event.id === selectedEvent.id);
      if (index !== -1) {
        updateEvent(index);
      }
    }
    handleClose();
  };

  const handleRemoveEvent = async () => {
    if (selectedEvent) {
      const index = events.findIndex((event) => event.id === selectedEvent.id);
      if (index !== -1) {
        removeEvent(index);
      }
    }
    handleClose();
  };

  const customSlotRemoveEvent = (id) => {
    const index = events.findIndex((event) => event.id === id);
    if (index !== -1) {
      removeEvent(index);
    }
  };

  const handleSelectSlot = ({ start, end }) => {
    if (ignoreNextSlotSelect) {
      setIgnoreNextSlotSelect(false);
      return;
    }

    const formattedStartDate = moment(start).format('YYYY-MM-DD');
    if (currentView === 'month') {
      addEvent({ date: formattedStartDate });
    } else {
      const formattedStartTime = moment(start).format('YYYY-MM-DDTHH:mm:ss');
      addEvent({ date: formattedStartDate, hour: formattedStartTime });
    }
  };

  const handleEventClick = (event, e) => {
    handleClickMore(e, event);
  };

  const handleDayViewChange = (date) => {
    onViewChange('day');
    setSelectedDate(date);
    setIgnoreNextSlotSelect(true);
  };

  const filterEventsForSlot = (date) => {
    return events.filter((event) => {  
      return (
        moment(event.start).isSame(date, 'day') ||
        moment(event.end).isSame(date, 'day')
      );
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px',
        paddingRight: 20,
        minHeight: '80vh',
      }}
    >
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{
          height: '100%',
          minHeight: '80vh',
          borderRadius: '12px',
        }}
        selectable
        onSelectSlot={currentView !== 'month' && selectable ? handleSelectSlot : null}
        onSelectEvent={currentView !== 'month' && selectable ? handleEventClick : null}
        views={['month', 'week', 'day']}
        view={currentView}
        onView={onViewChange}
        date={selectedDate}
        onNavigate={setSelectedDate}
        components={{
          toolbar: CustomToolbar,
          dateCellWrapper: ({ value, children }) => (
            <CustomSlot
              value={value}
              events={filterEventsForSlot(value)}
              onSelectSlot={handleSelectSlot}
              updateEvent={(id) => customSlotUpdateEvent(id)}
              removeEvent={(id) => customSlotRemoveEvent(id)}
              changeToDayView={handleDayViewChange}
              selectable={selectable}
            >
              {children}
            </CustomSlot>
          ),
        }}
      />

      {selectedEvent && (
        <EventMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleClickUpdateEvent={handleClickUpdateEvent}
          handleRemoveEvent={handleRemoveEvent}
          data={selectedEvent}
          index={events.indexOf(selectedEvent)}
        />
      )}
    </div>
  );
};

export default Scheduler;