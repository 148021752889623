import {makeStyles} from "@material-ui/core/styles";

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  title: {
    marginBottom: 20
  },
  filterIcon: {
    fill: COLOR_LIGHT,
    width: 18,
    height: 18,
    marginRight: 8
  },
  topContainer: {
    display: 'block',
    width: '100%',
    marginBottom: 30,
    boxSizing: 'border-box'
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 15,
    padding: 20,
    borderRadius: '20px !important',
    backgroundColor: ZONE_BACKGROUND_COLOR,
    boxSizing: 'border-box',
  },
  topContainerLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    float: 'left'
  },
  topButtonsContainer: {
    display: 'inline-block',
    width: '100%'
  },
  selector: {
    backgroundColor: ZONE_BACKGROUND_COLOR
  },
  addButton: {
    float: 'right'
  },
  dataZone: {
    width: '100%',
    backgroundColor: ZONE_BACKGROUND_COLOR,
    borderRadius: 18,
    listStyleType: 'none',
    [theme.breakpoints.up('xs')]: {
      padding: 25
    },
    [theme.breakpoints.up('sm')]: {
      padding: 25
    },
    [theme.breakpoints.up('md')]: {
      padding: 25
    },
    [theme.breakpoints.up('lg')]: {
      padding: 30
    }
  },
  championshipButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'right'
  },
  leftButton: {
    [theme.breakpoints.up('xs')]: {
      marginRight: 5
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 10
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: 12
    }
  },
  listIcon: {
    width: 20,
    height: 20,
    fill: COLOR_DARK,
    marginRight: 8
  },
  modalContainer: {
    [theme.breakpoints.up('xs')]: {
      padding: 20
    },
    [theme.breakpoints.up('sm')]: {
      padding: 20
    },
    [theme.breakpoints.up('md')]: {
      padding: 30
    },
    [theme.breakpoints.up('lg')]: {
      padding: 30
    }
  },
  modalBtnZone: {
    marginTop: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  modalBtn: {
    width: 200
  },
  inputInline: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  input: {
    marginBottom: "15px !important"
  },
  modalRightElement: {
    marginLeft: 10
  },
  inputRight: {
    marginLeft: 10
  },
  noTeamsInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  modalTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  modalTitleIcon: {
    stroke: COLOR_LIGHT,
    fill: COLOR_LIGHT,
    width: 20,
    height: 20,
    backgroundColor: ZONE_BACKGROUND_COLOR,
    padding: 7,
    borderRadius: '50%',
    cursor: 'pointer',
    "&:hover": {
      opacity: '0.8',
    },
    marginRight: 10,
  },
  matchCard: {
    cursor: 'pointer',
    "&&:hover": {
      filter: 'brightness(95%)',
      boxShadow: 'none'
    }
  }
}));